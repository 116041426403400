<template>
    <slide-comp :size="slideSize" class="notice_popup ncs" arrowLeft="/images/arrow_popup_left.png" arrowRight="/images/arrow_popup_right.png" v-if="ncsDetailData.detailClassCd">
        <template v-slot:top>
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
            </div>
        
            <div class="name">{{(isAdmin || isMine)  ? ncsDetailData.mberNm : ncsDetailData.mberNm.substr(0, 1) + '**'}} <span>({{ncsDetailData.gender | gender}} / {{ncsDetailData.birthYr | birthYear}})</span></div><div class="mem_num"><img src="/images/tec/myp/logo_sm.png" alt="하이프로로고"/></div>
            <div class="tit">NCS 직무 자가진단 평가결과</div>
            <div class="level-box"><img :src="'/images/tec/prf/ncs_' + ncsDetailData.ncsLevelCd + ncsDetailData.ncsGrade + '.png'" alt="레벨이미지"/></div>
        </template>
        <template  v-slot="slide">
            <div v-if="slide.index == 0">
                <div class="ncs-item">[{{ncsDetailData.detailClassNm}} / {{ncsDetailData.ncsLevelCd.toUpperCase()}}{{ncsDetailData.ncsGrade.toUpperCase()}}]</div>
                <div class="Board type3">
                    <!-- NCS DETAIL TABLE -->
                    <ncsJobTable type="popup"  :ncsJobObj="ncsDetailData"/>
                </div>
            </div>
            
            <div v-if="slide.index != 0">
                <div class="sub_tit">{{ncsEvalList[slide.index - 1].evalList[0].abilUnitNm}}</div>
                <div class="Board type3">
                    <table class="Board_type3" style="border-top:hidden;">
                        <colgroup>
                            <col width="20%">
                            <col width="65%">
                            <col width="10%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>진단영역</th>
                                <th>진단문항</th>
                                <th>평가</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in ncsEvalList[slide.index - 1].evalList" :key="row.selfDiagQeusSeq">
                                <td v-if="row.mergeChkCnt == row.mergeCnt" :rowspan="row.mergeCnt" class="score">{{row.abilUnitElemNm}}</td>
                                <td>{{row.qeusCont}}</td>
                                <td class="score" v-if="row.chk == '1'">매우미흡</td>
								<td class="score" v-else-if="row.chk == '2'">미흡</td>
								<td class="score" v-else-if="row.chk == '3'">보통</td>
								<td class="score" v-else-if="row.chk == '4'">우수</td>
								<td class="score" v-else>매우우수</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </slide-comp>
</template>

<script>
import slideComp from '@/components/SlideComp.vue'
import ncsJobTable from '@/components/highpro/ncsEval/ncsJobTable.vue';

export default {
    props : ['param'],
    data() {
        return {
            indMberSeq : this.param.indMberSeq,
            detailClassCd : this.param.detailClassCd,
            techGradeVerSeq : this.param.techGradeVerSeq,
            ncsDetailData : {},
            ncsEvalList : [],

            slideSize : 1,

            isAdmin : this.$store.getters.isAdmin,
            isMine : this.$store.state.userInfo.mberSeq == this.param.indMberSeq,
        }
    },

	components : {
		slideComp, ncsJobTable
	},

    watch : {
        
    },

    mounted() {
        //console.log('this.param', this.param);
        this.openNcsSlidePopup();

        //console.log('IS ADMIN ?', this.isAdmin);
    },

    methods : {
        openNcsSlidePopup() {
            var sendParam = {};
            sendParam.indMberSeq = this.indMberSeq;
            sendParam.detailClassCd = this.detailClassCd;
            sendParam.techGradeVerSeq = this.techGradeVerSeq;

            this.$.httpPost('/api/mem/prf/openNcsSlidePopup', sendParam)
                .then((res) => {
                    //console.log('openNcsSlidePopup RESULT ', res);
                    //console.log('slideSize ', res.data.ncsEvalList.length + 1);

                    this.slideSize = res.data.ncsEvalList.length + 1;
                    this.ncsDetailData = res.data.ncsDetailData;
                    this.ncsEvalList = res.data.ncsEvalList;
                    this.$emit('updated');
                }) 
        }
    }


}
</script>